"use client";

import { Roboto_Mono } from "next/font/google";
import { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "@/styles/404.module.scss";

const robotoMono = Roboto_Mono({
  subsets: ["latin"],
  display: "swap",
});

export default function GlobalError() {
  const text =
    "We're upgrading for a better experience. Please try again soon.";
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let charIndex = 0;
    let currentText = "";

    const typeText = () => {
      if (charIndex < text.length) {
        currentText += text[charIndex];
        setDisplayedText(currentText);
        charIndex++;
        setTimeout(typeText, 100);
      }
    };

    typeText();
  }, []);

  return (
    <html>
      <body
        className={clsx([
          styles["not-found__body"],
          robotoMono.className,
          "satellite-app",
        ])}
      >
        <div className={styles["not-found__container"]}>
          <div className={styles["not-found__center"]}>
            <div className={styles["not-found__copy-container"]}>
              <p className={styles["not-found__text"]}>
                {displayedText}
                <span className={styles["not-found__cursor"]}></span>
              </p>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}
